import React, { useState, useEffect } from "react";
import { ArticleCard } from "@molecules";
import { Container, Dropdown } from "@atoms";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { navigate } from "gatsby";
import loadable from "@loadable/component";
// import convertToOptions from "@utils/convertToOptions";

const Pagination = loadable(() => import("../molecules/Pagination"));

const ArticleList = ({ articles, type, enableFeaturedArticle }) => {
  const { topics, articleCategory } = useCategoriesData();
  const [typeFilter, setTypeFilter] = useState(type || "all");
  const [topicFilter, setTopicFilter] = useState("all");

  const types = {
    ...articleCategory.reduce((a, b) => ({ ...a, [b.slug]: b }), {}),
    all: { uri: "articles/" },
  };

  useEffect(() => {
    if (typeFilter !== type) {
      navigate(`/${types[typeFilter].uri}`);
    }
  }, [typeFilter]);

  const filteredArticles = articles.filter(a => {
    // we don't filter the type here since this is done at the query level
    const aTopics = a.topics.map(t => t.slug);
    const show = aTopics.includes(topicFilter) || topicFilter === "all";
    return show;
  });

  return (
    <section className="mt-8 md:mt-12">
      <Container variant="xs">
        {/* <div className="relative items-center lg:flex">
          <div className="flex h-8 items-center font-bold lg:absolute lg:left-0 lg:-translate-x-full lg:transform lg:pr-3">
            Filter By:
          </div>
          <div className="flex w-full flex-wrap gap-6">
            <Dropdown
              model={[typeFilter, setTypeFilter]}
              className="w-full md:w-1/3"
              options={[
                { all: "All Articles" },
                ...articleCategory.map(convertToOptions),
              ]}
            />
            <Dropdown
              model={[topicFilter, setTopicFilter]}
              className="w-full md:w-1/3"
              options={[{ all: "All Topics" }, ...topics.map(convertToOptions)]}
            />
          </div>
        </div> */}
        {/* the articles */}
        <ul className="mt-8 grid grid-cols-1 gap-12 md:mt-12 md:gap-12">
          <Pagination
            enableFeaturedArticle={enableFeaturedArticle}
            data={filteredArticles}
            component={ArticleCard}
            pageSize={6}
            rangeLimit={1}
            showNavigation
          />
        </ul>
      </Container>
    </section>
  );
};

export default ArticleList;
